<template>
  <v-container fluid class="text-center">
    <h1 class="blue-grey--text text--lighten-1 font-weight-light">Whoooops 404 - Not Found !</h1>
    <v-row justify="center" align="center">
      <v-img src="https://media.giphy.com/media/l41lFw057lAJQMwg0/giphy.gif" class="mt-5" contain max-width="480"
             max-height="270"/>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "error404"
}
</script>

<style scoped>

</style>
